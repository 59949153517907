<template>
  <div class="registerBox">
    <el-card>
      <div class="registerBox_title">巡查蝠后台管理系统</div>
      <el-steps :active="step" align-center>
        <el-step title="验证身份"></el-step>
        <el-step title="重置密码"></el-step>
        <el-step title="完成"></el-step>
      </el-steps>
      <div class="boxCard">
        <el-form ref="form" :model="register" label-width="80px">
          <el-form-item label="手机号" v-if="step ===1">
            <el-input placeholder="请输入11位手机号" v-model="register.mobile" class="input-with-select">
              <el-select v-model="register.country_code" slot="prepend" placeholder="请选择">
                <el-option label="+86" value="86"></el-option>
                <el-option label="+856" value="856"></el-option>
                <el-option label="+852" value="852"></el-option>
                <el-option label="+886" value="886"></el-option>
              </el-select>
            </el-input>
          </el-form-item>
          <el-form-item label="验证码" v-if="step ===1">
            <el-input placeholder="请输入验证码" v-model="register.sms_code" class="input-with-select">
              <el-button
                slot="append"
                @click="postMsg"
                :disabled="timeNum===60?false:true"
              >{{this.timeNum===60?'发送短信':this.timeNum+'s后重发'}}</el-button>
            </el-input>
          </el-form-item>
          <el-form-item label="密码" prop="name" v-if="step ===2">
            <el-input v-model="register.password" placeholder="6 - 16 位密码，区分大小写" show-password></el-input>
          </el-form-item>
          <el-form-item label="确认密码" v-if="step ===2">
            <el-input
              placeholder="确认密码"
              v-model="register.queryPassword"
              class="input-with-select"
              show-password
            ></el-input>
          </el-form-item>
          <div v-if="step ===3" class="success">密码修改成功</div>
          <div v-if="step ===3" class="success">{{this.timeNum3}}s后跳转到登录页或手动点击</div>
        </el-form>
        <el-button type="primary" class="rester-button" @click="next" v-if="step!=3">下一步</el-button>
      </div>
      <el-link type="primary" @click="toLogin">已有帐号，点我登陆</el-link>
    </el-card>
  </div>
</template>

<script>
import { isTelAvailable } from "../../utils/verification";
import { account } from "../../utils/api";
import qs from "qs";
export default {
  name:'forgetPassword',
  data() {
    return {
      register: {
        mobile: "",
        country_code: "86",
        sms_code: "",
        ticket: "",
        randstr: "",
        password: "",
        queryPassword: "",
      },
      step: 1,
      timeNum: 60,
      timeNum3: 3,
      timer60: null,
      timer3: null,
    };
  },

  components: {},

  computed: {},
  mounted() {
    this.captcha = new TencentCaptcha("2091202582", this.validateFu);
  },
  beforeDestroy() {
    clearInterval(this.timer60);
    clearInterval(this.timer3);
    this.timer60 = null;
    this.timer3 = null;
  },
  methods: {
    toLogin() {
      this.$router.go(-1);
    },
    //点击下一步
    async next() {
      if (this.step === 1) {
        let result = await this.checkSmsCode();
        if (result.code === 0) {
          this.step++;
        } else {
          this.$message.error("请填写正确的验证码");
        }
      } else if (this.step === 2) {
        if (
          this.register.password.length < 6 ||
          this.register.password.length > 16
        ) {
          return this.$message.error("请输入6 - 16 位密码，区分大小写");
        } else if (this.register.password !== this.register.queryPassword) {
          return this.$message.error("两次输入密码不一致");
        }
        this.resetPassWord();
      }
      //   this.step++;
    },
    //  防水墙验证后回调函数
    async validateFu({ ret, ticket, randstr }) {
      if (ret === 0) {
        this.register.ticket = ticket;
        this.register.randstr = randstr;
        await this.getSmsCOde();
        this.timeDown();
      }
    },
    // 点击发送短信按钮
    postMsg() {
      if (isTelAvailable(this.register.mobile)) {
        //显示 腾讯防水墙
        this.captcha.show();
      } else {
        this.$message.error("请填写正确的手机号");
      }
    },
    // 获取验证码后回调
    async getSmsCOde() {
      //   let par = "?" + qs.stringify(this.register);
      let par = `?mobile=${this.register.mobile}&country_code=${this.register.country_code}&ticket=${this.register.ticket}&randstr=${this.register.randstr}`;
      const res = await this.$http.get(account.smsCode + par);
    },
    // 校验短信验证码
    async checkSmsCode() {
      let { mobile, country_code, sms_code } = this.register;
      const res = await this.$http.post(account.verifySmsCode, {
        mobile,
        country_code,
        sms_code,
      });
      return res;
    },
    // 修改密码
    async resetPassWord() {
      const res = await this.$http.post(account.resetPassword, this.register);
      if (res.code === 0) {
        this.step++;
        let that = this;
        this.timeDown3();
        setTimeout(function () {
          that.$router.push("login");
        }, 3000);
      }
    },
    // 倒计时60s
    timeDown() {
      let that = this;
      this.timer60 = setInterval(function () {
        that.timeNum--;
        that.timeNum = that.timeNum < 10 ? "0" + that.timeNum : that.timeNum;
        if (that.timeNum == 0) {
          clearInterval(that.timer);
          that.timeNum = 60;
        }
      }, 1000);
    },
    timeDown3() {
      let that = this;
      this.timer3 = setInterval(function () {
        that.timeNum3--;
        if (that.timerNum3 == 0) {
          clearInterval(that.timer);
          that.timeNum = 60;
        }
      }, 1000);
    },
  },
};
</script>
<style >
.el-input {
  width: 380px;
}
.el-select .el-input {
  width: 90px;
}
</style>
<style lang='scss' scoped>
.el-steps {
  width: 600px;
  margin-bottom: 50px;
}
.registerBox {
  @include flex_center;
  width: 100vw;
  height: 100vh;
  .registerBox_title {
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #666666;
    line-height: 28px;
    text-align: center;
    margin-bottom: 30px;
  }
  .el-card {
    //  @include flex_center_col;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 830px;
    height: 542px;
    .boxCard {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    .el-form-item .el-button {
      background: $myColor;
      color: #ffffff;
      font-weight: 700;
    }
    .rester-button {
      width: 360px;
      height: 40px;
      text-align: center;
      background: linear-gradient(180deg, #38aff4 0%, #1477e1 100%);
      box-shadow: 0px 0px 10px 0px rgba(0, 101, 201, 0.37);
      border-radius: 4px;
      margin-top: 30px;
    }
    .checked {
      margin-top: 30px;
    }
    .el-link {
      position: absolute;
      right: 25px;
      bottom: 25px;
    }
  }
}
a {
  text-decoration: none;
  color: $myColor;
}
.success {
  //   width: 300px;
  text-align: center;
  line-height: 50px;
}
</style>
